import { ChoiceGroup, IChoiceGroupProps } from "@fluentui/react";

export function HorizontalChoiceGroup(props: IChoiceGroupProps) {
  return (
    <ChoiceGroup
      {...props}
      styles={{
        flexContainer: {
          "> .ms-ChoiceField": {
            display: "inline-block",
            marginRight: 10,
          },
        },
      }}
    />
  );
}
