import { createContext, PropsWithChildren, useEffect, useState } from "react";

const minGridTileSize = 100;
const maxGridTileSize = 300;
const contentPaddingSize = 10;

interface ContextValue {
  isMobile: boolean;
  gridTileWidth: number;
  contentWidth: number;
  contentPadding: number;
}

const defaultValue: ContextValue = {
  isMobile: false,
  gridTileWidth: maxGridTileSize,
  contentWidth: document.documentElement.clientWidth,
  contentPadding: 0,
};

export const SizingContext = createContext<ContextValue>(defaultValue);

export function SizingProvider(props: PropsWithChildren<{}>) {
  const [value, setValue] = useState<ContextValue>(defaultValue);
  useEffect(() => {
    const handler = () => {
      const { clientWidth } = document.documentElement;
      const isMobile = clientWidth < 600;
      const contentPadding = isMobile ? contentPaddingSize : 0;
      const contentWidth = clientWidth - 2 * contentPadding;
      setValue({
        isMobile,
        gridTileWidth: Math.max(
          minGridTileSize,
          Math.min(Math.floor(contentWidth / 2), maxGridTileSize)
        ),
        contentWidth,
        contentPadding,
      });
    };
    handler();
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);
  return (
    <SizingContext.Provider value={value}>
      {props.children}
    </SizingContext.Provider>
  );
}
