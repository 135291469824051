import { ISpinnerStyles, Spinner } from "@fluentui/react";

export const LoadingSpinner = () => {
  return (
    <Spinner label="Loading..." labelPosition="right" styles={spinnerStyles} />
  );
};

const spinnerStyles: ISpinnerStyles = {
  root: {
    margin: 20,
  },
};
