import { Stack, StackItem } from "@fluentui/react";
import { useContext } from "react";
import { useTitle } from "../Common/UseTitle";
import { AllProductsContext } from "./Contexts/AllProductsContext";
import Tag from "./Tag";

export default function Home() {
  useTitle();
  const { allProducts } = useContext(AllProductsContext);
  return (
    <Stack>
      {allProducts?.tags.map((tag) => {
        return (
          <StackItem key={tag}>
            <Tag tag={tag} embedded />
          </StackItem>
        );
      })}
    </Stack>
  );
}
