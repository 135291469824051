import {
  ILinkStyles,
  Image,
  ImageFit,
  Link,
  mergeStyleSets,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTitle } from "../Common/UseTitle";
import { AllProductsContext } from "./Contexts/AllProductsContext";
import { SizingContext } from "./Contexts/SizingContext";

interface Props {
  tag?: string;
  embedded?: boolean;
}

export default function Tag(props: Props) {
  const params = useParams<{ tag: string }>();
  const tag = props.tag || params.tag!;
  useTitle(tag, props.embedded);
  const navigate = useNavigate();
  const { allProducts } = useContext(AllProductsContext);
  const { gridTileWidth } = useContext(SizingContext);
  const tagProducts = allProducts
    ? allProducts.tagProducts[tag].map((id) => {
        return {
          id,
          ...allProducts.products[id],
        };
      })
    : undefined;
  const tileWidth = gridTileWidth - 2 * tilePadding;
  return (
    <>
      <Link styles={titleStyles} onClick={() => navigate(`/tags/${tag}`)}>
        {tag}
      </Link>
      <Stack horizontal wrap tokens={{ childrenGap: tilePadding }}>
        {tagProducts?.map((product) => {
          return (
            <StackItem key={product.id}>
              <Link
                className={classNames.productTile}
                onClick={() => navigate(`/products/${product.id}`)}
                styles={{ root: { width: tileWidth } }}
              >
                <Image
                  src={product.image}
                  imageFit={ImageFit.centerCover}
                  width={tileWidth}
                  height={tileWidth}
                  alt={product.name}
                />
                <Text>{product.name}</Text>
              </Link>
            </StackItem>
          );
        })}
      </Stack>
    </>
  );
}

const tilePadding = 15;

const titleStyles: ILinkStyles = {
  root: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 10,
  },
};

const classNames = mergeStyleSets({
  productTile: {
    textAlign: "center",
    paddingBottom: tilePadding,
    ":hover": {
      boxShadow:
        "0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%)",
    },
  },
});
