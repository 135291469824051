import { SVGAttributes } from "react";

interface Props extends SVGAttributes<SVGSVGElement> {}

export const Logo = (props: Props) => {
  return (
    <svg {...props} viewBox="0 0 74.2 51.1" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-116 -123)">
        <path
          d="m121 147c-0.315 1.16-0.511 2.47-0.458 3.76 0.0526 1.29 0.353 2.55 0.774 3.71 0.421 1.16 0.961 2.21 1.7 3.47 0.736 1.26 1.67 2.73 2.82 3.99 1.16 1.26 2.54 2.31 3.96 3.26 1.43 0.946 2.9 1.79 5.05 2.94 2.15 1.16 4.97 2.63 7.49 3.62 2.52 0.991 4.75 1.5 6.59 1.98s3.31 0.931 6.54 0.702c3.24-0.229 8.24-1.14 12.8-3.83 4.57-2.69 8.69-7.16 10.5-10.5 1.78-3.36 1.21-5.61 0.656-7.51-0.551-1.89-1.08-3.42-1.89-4.76-0.809-1.33-1.9-2.47-2.96-3.31-1.07-0.84-2.12-1.38-3.22-1.97-1.1-0.586-2.24-1.22-4.61-1.58s-5.98-0.451-8.61-0.661c-2.63-0.21-4.28-0.541-5.9-1.23-1.62-0.691-3.21-1.74-4.75-2.64-1.53-0.901-3-1.65-4.9-2.03-1.89-0.376-4.21-0.376-6.25-0.165s-3.81 0.631-5.39 1.3c-1.58 0.668-2.96 1.58-4.12 2.64-1.16 1.05-2.09 2.24-2.82 3.21-0.736 0.969-1.28 1.72-1.76 2.59-0.488 0.875-0.924 1.87-1.24 3.03z"
          fill="#fcda5e"
        />
        <path
          d="m127 141s-0.858-0.858-0.879-1.04c-0.0204-0.184-0.0613-0.552-0.102-0.92-0.49-0.286-0.981-0.572-1.44-0.981s-0.879-0.94-1.25-1.63c-0.375-0.695-0.702-1.55-0.899-2.31-0.198-0.756-0.266-1.41-0.123-2.13 0.143-0.715 0.497-1.49 0.852-2.27 0.327 0.341 0.654 0.682 0.961 1.13s0.593 1.01 0.831 1.53c0.238 0.518 0.429 0.995 0.572 1.45 0.143 0.456 0.238 0.892 0.334 1.33 0.286-0.191 0.572-0.382 0.811-0.838 0.238-0.456 0.429-1.18 0.62-1.85s0.381-1.28 0.668-1.89c0.286-0.613 0.668-1.23 1.17-1.87s1.13-1.31 1.94-1.96c0.811-0.647 1.81-1.27 2.97-1.92 1.16-0.647 2.5-1.31 3.84-1.98 0.422 0.477 0.845 0.954 1.18 1.4 0.334 0.45 0.579 0.872 0.77 1.46 0.191 0.593 0.327 1.36 0.279 2.29-0.0477 0.933-0.279 2.04-0.606 2.97-0.327 0.933-0.749 1.7-1.23 2.46s-1.01 1.53-1.83 2.26c-0.817 0.736-1.92 1.44-3.07 1.97-1.14 0.525-2.33 0.865-3.09 1.04-0.756 0.17-1.08 0.17-1.41 0.17l-0.0409 2-1.84 0.123z"
          fill="#1bb58f"
        />
        <g transform="translate(51.1 67.3)" fill="#0572d9">
          <path
            transform="matrix(.265 0 0 .265 56.1 41.3)"
            d="m51.4 130c-2.24 2e-3 -4.48 0.167-5.96 0.418-2.36 0.402-5.1 1.42-6.72 2.25s-2.58 1.62-3.54 2.42c-0.154 0.36-0.31 0.722 0.0508 6.93s1.24 18.3 2.11 24.7 1.75 7.42 2.65 8.32c0.901 0.901 1.83 1.78 5.1 1.78 3.27-5.6e-4 8.88-0.876 12.8-2.04s6.28-2.6 8.24-4.12 3.55-3.12 4.63-5.1c1.08-1.98 1.65-4.35 1.39-6.62-0.257-2.27-1.34-4.43-2.78-5.87s-3.25-2.16-5.05-2.88c1.85-2.68 3.71-5.36 4.51-7.6 0.798-2.24 0.541-4.04-0.488-5.69-1.03-1.65-2.83-3.14-5.07-4.33s-4.92-2.06-8.06-2.42c-1.18-0.135-2.52-0.192-3.87-0.191zm-2.16 8.6c0.865-6e-3 1.81 0.0392 2.74 0.141 1.66 0.18 3.28 0.542 4.33 1.03 1.04 0.49 1.51 1.11 1.78 1.97 0.27 0.863 0.346 1.97-0.645 2.61-0.991 0.643-3.05 0.824-4.76 0.953s-3.08 0.205-4.38 0.193c-1.3-0.0119-2.53-0.114-3.16-0.963-0.631-0.849-0.661-2.45-0.676-3.25-0.0151-0.8-0.0162-0.801 0.0352-0.98s0.154-0.539 0.566-0.861c0.412-0.322 1.13-0.607 2.34-0.748 0.53-0.0619 1.15-0.0971 1.83-0.102zm1.72 15.7c1.06-0.0133 2.12 0.0209 3.1 0.156 1.31 0.18 2.5 0.54 3.8 1.13 1.3 0.592 2.72 1.42 3.38 2.59 0.669 1.17 0.593 2.69-0.166 3.76-0.759 1.07-2.2 1.69-3.91 2.36-1.71 0.669-3.69 1.39-5.54 1.71-1.84 0.322-3.54 0.244-4.85 0.0254s-2.24-0.579-2.78-2.21c-0.539-1.63-0.688-4.54-0.52-6.21 0.168-1.68 0.654-2.12 1.14-2.57 0.384-0.151 0.77-0.301 1.64-0.428 0.874-0.127 2.24-0.23 3.64-0.281 0.351-0.0129 0.704-0.0229 1.06-0.0273z"
          />
          <path d="m83.3 74.6c-0.396-0.0056-0.762 0.0581-1.1 0.144-0.487 0.125-0.906 0.298-1.33 0.522-0.429 0.224-0.867 0.499-1.2 0.843-0.337 0.344-0.573 0.758-0.776 1.21s-0.371 0.944-0.448 1.56c-0.077 0.617-0.0622 1.36 0.114 2.14 0.176 0.783 0.513 1.61 0.891 2.19 0.378 0.58 0.798 0.918 1.27 1.14 0.472 0.222 0.997 0.327 1.65 0.344 0.658 0.0168 1.45-0.0556 2.14-0.309 0.691-0.253 1.28-0.687 1.77-1.17 0.482-0.479 0.853-1 1.11-1.54 0.253-0.532 0.388-1.07 0.448-1.63 0.0602-0.556 0.0454-1.13-0.0822-1.69-0.128-0.556-0.368-1.1-0.708-1.59-0.34-0.496-0.778-0.949-1.33-1.33-0.556-0.378-1.23-0.682-1.84-0.795-0.192-0.0354-0.378-0.0522-0.558-0.0548zm-0.429 2.66c0.0801 5.29e-4 0.162 0.0034 0.243 0.0083 0.328 0.0193 0.665 0.0722 0.954 0.186 0.289 0.113 0.53 0.287 0.715 0.513 0.185 0.226 0.316 0.506 0.397 0.814s0.115 0.646 0.091 0.983c-0.0241 0.337-0.106 0.674-0.207 0.985-0.101 0.311-0.222 0.595-0.41 0.824-0.188 0.229-0.443 0.402-0.788 0.547s-0.778 0.26-1.14 0.274c-0.361 0.0145-0.65-0.0723-0.93-0.241-0.279-0.169-0.549-0.419-0.751-0.696-0.202-0.277-0.338-0.58-0.405-0.732h5.29e-4c-0.0675-0.152-0.0677-0.152-0.0749-0.25s-0.0217-0.296-0.0289-0.6-0.0071-0.713 0.0506-1.04c0.0578-0.323 0.173-0.559 0.332-0.761s0.361-0.371 0.573-0.499 0.434-0.215 0.704-0.265c0.202-0.0379 0.432-0.0554 0.672-0.0537z" />
          <path
            d="m91.9 79.6c0.0602-0.766 0.181-2.3 0.308-3.27s0.263-1.37 0.462-1.67c0.2-0.294 0.465-0.477 0.691-0.612s0.414-0.222 0.67-0.19 0.578 0.181 0.805 0.333 0.356 0.306 0.826 1.11c0.47 0.802 1.28 2.25 2.09 3.7 0.188-1.38 0.376-2.76 0.537-3.56s0.296-0.995 0.465-1.17c0.169-0.176 0.371-0.325 0.598-0.325s0.477 0.149 0.67 0.325c0.193 0.176 0.328 0.378 0.414 0.638 0.0866 0.26 0.125 0.578 0.0673 1.6-0.0578 1.02-0.212 2.74-0.34 3.75-0.128 1.01-0.229 1.32-0.347 1.61-0.118 0.296-0.253 0.585-0.414 0.807s-0.349 0.376-0.551 0.467-0.419 0.12-0.636 0.104-0.433-0.0794-0.653-0.188-0.441-0.262-0.788-0.761-0.819-1.34-1.17-2.03-0.59-1.23-0.766-1.52c-0.176-0.294-0.291-0.342-0.369-0.275s-0.116 0.251-0.159 0.865-0.0915 1.66-0.157 2.27c-0.065 0.607-0.147 0.775-0.248 0.944s-0.222 0.337-0.407 0.448c-0.185 0.111-0.436 0.164-0.645 0.123s-0.378-0.176-0.506-0.303-0.214-0.248-0.299-0.349c-0.0843-0.101-0.166-0.183-0.2-0.53-0.0338-0.347-0.0194-0.959-0.0122-1.26 0.0072-0.306 0.0072-0.306 0.0674-1.07z"
            fill="#0572d9"
          />
          <path d="m110 74.2c-0.381-0.118-0.906-0.219-1.39-0.195-0.482 0.024-0.92 0.173-1.43 0.4-0.506 0.226-1.08 0.53-1.54 0.944-0.463 0.414-0.814 0.939-1.07 1.38s-0.407 0.797-0.484 1.31c-0.077 0.513-0.077 1.18-0.0433 1.78s0.101 1.12 0.27 1.57 0.438 0.819 0.85 1.19c0.412 0.371 0.966 0.742 1.48 0.978 0.513 0.236 0.985 0.337 1.44 0.405 0.458 0.0674 0.901 0.101 1.38 0.0505 0.482-0.0506 1-0.185 1.43-0.405 0.429-0.219 0.766-0.523 1-0.826 0.236-0.303 0.371-0.607 0.523-0.978s0.32-0.809 0.347-1.15c0.0266-0.344-0.089-0.595-0.258-0.831-0.169-0.236-0.39-0.458-0.761-0.636s-0.891-0.313-1.35-0.357c-0.462-0.0434-0.867 0.0048-1.13 0.123-0.263 0.118-0.383 0.306-0.46 0.494-0.0771 0.188-0.111 0.376-0.0769 0.544 0.0338 0.169 0.135 0.318 0.287 0.426 0.152 0.108 0.354 0.176 0.513 0.176s0.274-0.0675 0.368-0.0601c0.094 0.0074 0.166 0.0893 0.236 0.174 0.0698 0.0843 0.137 0.171 0.161 0.265 0.0239 0.0939 5e-3 0.195-0.0483 0.296-0.053 0.101-0.14 0.202-0.25 0.32-0.111 0.118-0.246 0.253-0.455 0.337-0.209 0.0843-0.494 0.118-0.804 0.094-0.311-0.024-0.648-0.106-0.959-0.258-0.311-0.152-0.595-0.373-0.831-0.686-0.236-0.313-0.424-0.718-0.518-1.09-0.094-0.371-0.094-0.708-0.0434-1.08s0.152-0.776 0.405-1.15 0.658-0.708 1.1-0.927c0.446-0.219 0.932-0.32 1.32-0.296 0.388 0.0242 0.677 0.174 0.99 0.366s0.65 0.429 0.869 0.607c0.219 0.178 0.32 0.299 0.438 0.376 0.118 0.077 0.253 0.111 0.448 0.0432s0.45-0.236 0.612-0.479c0.161-0.243 0.229-0.561 0.202-0.821-0.0264-0.26-0.147-0.462-0.335-0.691s-0.443-0.484-0.72-0.713c-0.277-0.229-0.576-0.431-0.843-0.6-0.267-0.169-0.503-0.303-0.884-0.422z" />
          <path
            transform="matrix(.265 0 0 .265 56.1 41.3)"
            d="m133 177c-1.66-8e-3 -3.47 0.202-5.4 0.535-3.42 0.592-7.21 1.57-9.99 2.73s-4.56 2.5-5.28 4.4c-0.721 1.91-0.386 4.38-0.219 5.61 0.167 1.24 0.168 1.24 0.799 5.15 0.631 3.91 1.89 11.7 2.79 16.3s1.44 5.81 2.25 6.62c0.811 0.811 1.89 1.17 2.94 1.53 1.04 0.36 2.05 0.722 3.53 0.863 1.48 0.141 3.44 0.0635 5.87-0.426 2.43-0.489 5.34-1.39 7.6-2.12 2.25-0.734 3.85-1.3 5.19-2.38 1.34-1.08 2.42-2.68 3.37-4.25 0.953-1.57 1.78-3.12 2.06-4.7 0.283-1.58 0.027-3.2-0.514-4.83-0.54-1.62-1.36-3.24-2.45-4.46s-2.42-2.01-2.83-3.04c-0.413-1.03 0.103-2.29 0.631-3.79 0.528-1.49 1.07-3.22 0.992-4.8-0.0769-1.58-0.772-3.03-2.07-4.57-1.3-1.54-3.21-3.19-5.69-3.91-1.09-0.316-2.28-0.453-3.57-0.459zm-1.24 7.54c1.04-5e-3 2.06 0.094 2.83 0.449 1.38 0.631 1.97 2.07 2.3 3.46 0.335 1.39 0.411 2.73-0.348 3.45-0.759 0.72-2.36 0.824-4.92 1.11s-6.09 0.747-7.89 0.748-1.88-0.463-1.96-1.63c-0.0774-1.17-0.154-3.05-0.0645-4.19 0.09-1.13 0.401-1.6 0.504-1.75 0.103-0.154 0.101-0.154 0.225-0.254 0.123-0.1 0.557-0.452 1-0.594 0.445-0.142 1.41-0.268 2.35-0.361 0.94-0.0933 2.01-0.176 3.63-0.307 0.708-0.0573 1.52-0.125 2.33-0.129zm-0.0645 15.6c0.714-0.01 1.38 0.0214 1.96 0.15 1.16 0.258 1.98 0.902 2.76 1.71 0.772 0.811 1.49 1.79 1.85 2.64 0.36 0.85 0.359 1.57 0.127 2.42-0.232 0.849-0.695 1.83-1.81 2.5-1.12 0.669-2.9 1.03-4.83 1.35-1.93 0.322-4.02 0.605-5.42 0.477-1.4-0.128-2.13-0.668-2.58-0.99-0.451-0.322-0.63-0.426-0.811-1.6-0.181-1.17-0.361-3.41-0.451-4.53-0.0902-1.12-0.0905-1.12-0.0391-1.28s0.155-0.482 0.516-0.945c0.361-0.464 0.978-1.07 2.11-1.38 1.13-0.315 2.78-0.341 4.35-0.418 0.785-0.0386 1.55-0.09 2.27-0.0996z"
          />
          <path
            transform="matrix(.265 0 0 .265 56.1 41.3)"
            d="m182 184c-1.88 4e-3 -3.69 0.282-5.32 0.861-2.61 0.927-4.78 2.63-6.59 4.61s-3.28 4.25-4.15 6.21c-0.863 1.96-1.12 3.6-1.3 5.82-0.18 2.21-0.283 5 0.207 7.51 0.49 2.51 1.57 4.75 2.74 6.73s2.43 3.71 4.38 5.33 4.57 3.14 7.28 3.99c2.7 0.849 5.48 1.03 8.24 0.719 2.75-0.309 5.48-1.11 7.79-2.9 2.3-1.79 4.18-4.57 5.48-7.54s2.02-6.14 1.93-9.8-0.991-7.8-2.45-11c-1.45-3.15-3.46-5.32-6.03-6.98s-5.68-2.82-8.77-3.32c-1.16-0.188-2.31-0.284-3.44-0.281zm0.957 10.9c1.17-0.025 2.35 0.168 3.53 0.629 1.71 0.67 3.41 1.91 4.71 3.44s2.2 3.36 2.56 5.54 0.18 4.7-0.541 6.63c-0.721 1.93-1.98 3.27-4.02 4.12s-4.84 1.21-7.05 0.799c-2.21-0.411-3.84-1.6-5.21-2.98s-2.5-2.96-3.06-4.79c-0.564-1.83-0.564-3.92-0.564-4.96s7.6e-4 -1.04 0.0234-1.14c0.0227-0.0918 0.0676-0.277 0.113-0.461 0-0.0135-2.1e-4 -0.0256 0.18-0.432 0.18-0.406 0.541-1.2 1.04-2.02 0.502-0.811 1.15-1.64 2.28-2.41s2.76-1.49 4.43-1.8c0.523-0.0965 1.05-0.153 1.58-0.164z"
          />
          <path d="m116 94.4c0.215 0.95 0.644 2.85 0.988 3.99 0.344 1.14 0.603 1.52 0.828 1.8 0.225 0.276 0.416 0.446 0.62 0.541 0.204 0.0953 0.422 0.116 0.623 0.0101 0.201-0.106 0.385-0.337 0.504-0.61 0.119-0.272 0.174-0.586 0.0682-1.28-0.106-0.691-0.371-1.76-0.637-2.83 1.29 0.831 2.58 1.66 3.42 2.15 0.845 0.487 1.25 0.63 1.64 0.702 0.392 0.0715 0.773 0.0715 1.07-0.0477 0.3-0.119 0.518-0.358 0.637-0.63 0.119-0.272 0.14-0.579-0.0749-1.92-0.215-1.35-0.664-3.73-1.02-5.02-0.354-1.29-0.613-1.49-0.838-1.67-0.225-0.181-0.416-0.344-0.644-0.341-0.228 0.0033-0.494 0.174-0.675 0.412s-0.276 0.545-0.324 0.903c-0.0477 0.358-0.0477 0.766 0.058 1.54 0.106 0.777 0.317 1.92 0.528 3.07-0.45-0.409-0.899-0.817-1.8-1.4-0.903-0.583-2.26-1.34-3.1-1.77-0.845-0.429-1.18-0.531-1.44-0.474-0.266 0.0579-0.463 0.276-0.583 0.528-0.119 0.252-0.16 0.538-0.157 0.777 3e-3 0.238 0.0512 0.429 0.075 0.524s0.0238 0.0952 0.238 1.05z" />
          <path d="m134 84.6c-0.694 0.0722-1.5 0.217-2.23 0.472s-1.37 0.621-1.9 1.06c-0.525 0.434-0.93 0.935-1.18 1.66-0.25 0.723-0.347 1.67-0.347 2.46 4e-5 0.795 0.0964 1.44 0.366 2.17 0.27 0.727 0.713 1.54 1.35 2.18 0.641 0.641 1.48 1.11 2.34 1.33 0.857 0.217 1.73 0.178 2.51 0.0432 0.78-0.135 1.46-0.366 2.11-0.867s1.25-1.27 1.6-1.98c0.352-0.708 0.448-1.35 0.477-1.83 0.0289-0.472-0.01-0.771-0.125-1.11-0.116-0.337-0.308-0.713-0.626-0.983s-0.761-0.434-1.44-0.453c-0.679-0.0193-1.59 0.106-2.11 0.236-0.52 0.13-0.646 0.265-0.747 0.506s-0.178 0.588-0.13 0.862c0.0483 0.275 0.222 0.477 0.593 0.578s0.939 0.101 1.3 0.135c0.356 0.0338 0.501 0.101 0.602 0.251s0.159 0.381 0.106 0.713c-0.0531 0.332-0.217 0.766-0.453 1.09-0.236 0.323-0.544 0.535-1.06 0.621-0.52 0.0867-1.25 0.0482-1.93-0.207-0.674-0.255-1.29-0.727-1.68-1.3-0.39-0.573-0.554-1.25-0.52-1.94 0.0337-0.694 0.265-1.41 0.67-1.86s0.983-0.646 1.61-0.809c0.626-0.164 1.3-0.299 1.83-0.366s0.901-0.0674 1.25-0.236c0.352-0.169 0.679-0.506 0.814-0.814 0.135-0.308 0.0771-0.588-0.154-0.862-0.231-0.275-0.636-0.544-1.13-0.679s-1.07-0.135-1.76-0.0627z" />
        </g>
      </g>
    </svg>
  );
};
