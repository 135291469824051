import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { OrderProduct } from "../../server/ServerSchemas";

export interface ShoppingCartItems {
  [sku: string]: OrderProduct;
}

const storageKey = "cartItems";
const checkoutKey = "checkoutStart";

export const ShoppingCartContext = createContext<{
  cartItems: ShoppingCartItems;
  totalItems: number;
  setCartItems: Dispatch<SetStateAction<ShoppingCartItems>>;
  isPrimaryCheckout: boolean;
  startCheckout: () => void;
}>(undefined as any);

export function ShoppingCartProvider(props: PropsWithChildren<{}>) {
  const [items, setItems] = useState<ShoppingCartItems>({});
  const [isPrimaryCheckout, setPrimaryCheckout] = useState(true);
  useEffect(() => {
    setItems(getFromStorage());
    const handler = (event: StorageEvent) => {
      if (event.storageArea === localStorage) {
        if (event.key === storageKey) {
          setItems(getFromStorage());
        } else if (event.key === checkoutKey) {
          setPrimaryCheckout(false);
        }
      }
    };
    window.addEventListener("storage", handler);
    return () => {
      window.removeEventListener("storage", handler);
    };
  }, []);
  const setCartItems: Dispatch<SetStateAction<ShoppingCartItems>> = useCallback(
    (valueOrFunc) => {
      setItems((current) => {
        const value =
          typeof valueOrFunc === "function"
            ? valueOrFunc(current)
            : valueOrFunc;
        saveToStorage(value);
        return value;
      });
    },
    []
  );
  const totalItems = useMemo(() => {
    return Object.values(items).reduce((curr, item) => curr + item.quantity, 0);
  }, [items]);
  const startCheckout = useCallback(() => {
    window.localStorage.setItem(checkoutKey, new Date().getTime().toString());
    setPrimaryCheckout(true);
  }, [setPrimaryCheckout]);
  return (
    <ShoppingCartContext.Provider
      value={{
        cartItems: items,
        totalItems,
        setCartItems,
        isPrimaryCheckout,
        startCheckout,
      }}
    >
      {props.children}
    </ShoppingCartContext.Provider>
  );
}

interface StorageItem {
  items: ShoppingCartItems;
}

function getFromStorage(): ShoppingCartItems {
  const items: ShoppingCartItems = {};
  let rawItems: ShoppingCartItems | undefined;
  try {
    const raw = window.localStorage.getItem(storageKey);
    const storageItem: StorageItem = raw && JSON.parse(raw);
    rawItems = storageItem?.items;
  } catch (e) {
    console.error("Invalid cart items from storage");
    window.localStorage.removeItem(storageKey);
  }

  if (rawItems) {
    Object.keys(rawItems).forEach((key) => {
      const rawItem = rawItems![key];
      if (rawItem) {
        const { productId, flavor, productName, flavorName, quantity, price } =
          rawItem;
        if (
          key === `${productId}__${flavor}` &&
          isString(productName) &&
          isString(flavorName) &&
          isNumber(quantity) &&
          isNumber(price)
        ) {
          items[key] = {
            productId,
            flavor,
            productName,
            flavorName,
            quantity,
            price,
          };
        }
      }
    });
  }

  return items;
}

function saveToStorage(items: ShoppingCartItems) {
  window.localStorage.setItem(storageKey, JSON.stringify({ items }));
}

function isString(s: string) {
  return typeof s === "string";
}

function isNumber(n: number) {
  return typeof n === "number" && !isNaN(n);
}
