import { SVGAttributes } from "react";

interface Props extends SVGAttributes<SVGSVGElement> {}

export const TextLogo = (props: Props) => {
  return (
    <svg {...props} viewBox="0 0 36.7 4.5" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-42.9 -49.7)">
        <g transform="translate(-38.4 -62.2)" fill="#0572d9">
          <path d="m81.4 113c-0.0022 0.0132-0.0067 0.0394 0.0138 0.516 0.0205 0.477 0.0659 1.4 0.119 1.92 0.0529 0.514 0.113 0.616 0.181 0.688 0.0675 0.0722 0.142 0.116 0.218 0.0986 0.0757-0.017 0.153-0.094 0.208-0.191 0.0554-0.0975 0.0891-0.215 0.0976-0.747 0.0085-0.531-0.0084-1.48-0.0384-2s-0.0734-0.624-0.154-0.675c-0.0806-0.0507-0.199-0.0507-0.291-0.0291-0.0928 0.0217-0.16 0.065-0.215 0.136-0.0542 0.0711-0.0952 0.17-0.116 0.219-0.0204 0.0493-0.0204 0.0493-0.0227 0.0625z" />
          <path
            transform="matrix(.265 0 0 .265 56.1 41.3)"
            d="m107 271c-0.372 0.011-0.713 0.304-0.992 0.639-0.319 0.383-0.574 0.741-1.24 2.21-0.665 1.47-1.68 3.98-2.19 5.19-0.51 1.21-0.511 1.21-0.613 1.44s-0.307 0.686-0.277 1.13c0.0298 0.441 0.295 0.866 0.678 1.01 0.383 0.144 0.885 9e-3 1.27-0.252 0.383-0.26 0.646-0.646 0.926-1.25 0.28-0.608 0.577-1.44 0.873-2.27l3.65-0.0586c0.406 1.28 0.817 2.58 1.2 3.38s0.742 1.14 1.1 1.11c0.36-0.0326 0.72-0.439 0.889-0.904 0.169-0.466 0.146-0.993-0.156-2.15-0.303-1.16-0.886-2.94-1.49-4.44-0.601-1.5-1.22-2.72-1.82-3.52-0.601-0.801-1.18-1.18-1.64-1.25-0.057-8e-3 -0.111-0.0113-0.164-0.01zm-8e-3 2.59 1.41 2.49-2.53 0.0349z"
          />
          <path d="m86.2 114c0.0042 0.01 0.0124 0.029 0.0082 0.363-0.0042 0.333-0.0207 0.981 0.0083 1.34 0.0291 0.357 0.104 0.423 0.194 0.475s0.198 0.0909 0.281 0.0716 0.14-0.0965 0.178-0.192c0.0372-0.095 0.0537-0.208 0.073-0.444s0.0414-0.594 0.0634-0.952c0.367 0.405 0.733 0.811 0.97 1.06 0.237 0.251 0.345 0.347 0.474 0.366 0.129 0.0191 0.281-0.0387 0.357-0.135 0.0757-0.0963 0.0757-0.231 0.0909-0.642 0.0152-0.411 0.0455-1.1 0.0414-1.48-0.0041-0.386-0.0426-0.471-0.11-0.529-0.0674-0.058-0.164-0.0883-0.247-0.0746s-0.152 0.0716-0.2 0.143c-0.0483 0.0717-0.0758 0.157-0.0896 0.418-0.0138 0.261-0.0138 0.696-0.0138 1.13-0.446-0.455-0.893-0.909-1.16-1.19-0.265-0.281-0.347-0.389-0.442-0.437-0.0951-0.0484-0.203-0.0374-0.284 0.0164-0.0814 0.0538-0.137 0.15-0.165 0.272s-0.0317 0.267-0.0331 0.34c-0.0014 0.0729-0.0013 0.0729 0.0029 0.0827z" />
          <path
            transform="matrix(.265 0 0 .265 56.1 41.3)"
            d="m134 270c-0.731 5e-3 -1.42 0.127-2.1 0.354-0.855 0.287-1.68 0.743-2.14 1.18-0.464 0.435-0.568 0.849-0.619 1.05-0.0515 0.206-0.0513 0.206 0.361 1.83 0.413 1.63 1.24 4.88 1.78 6.71 0.538 1.83 0.788 2.22 1.33 2.37 0.538 0.147 1.36 0.0437 2.42-0.236 1.06-0.28 2.36-0.738 3.13-1.17 0.773-0.434 1.02-0.847 1.21-1.28 0.184-0.434 0.302-0.889 0.354-1.74 0.0517-0.847 0.0378-2.09-0.168-2.79-0.206-0.7-0.605-0.862-1.14-0.943-0.538-0.0813-1.22-0.0821-1.33-0.34-0.111-0.258 0.346-0.772 0.633-1.13 0.287-0.361 0.405-0.567 0.361-1.08-0.044-0.515-0.249-1.34-0.867-1.89-0.618-0.546-1.65-0.811-2.61-0.885-0.18-0.0138-0.357-0.021-0.531-0.0215-0.0237-6e-5 -0.0467-1.7e-4 -0.0703 0zm-0.535 2.61c0.058-3.2e-4 0.117 2e-3 0.178 6e-3 0.486 0.0298 1.06 0.191 1.3 0.479 0.235 0.287 0.131 0.7-0.207 1.03-0.338 0.331-0.912 0.582-1.43 0.707-0.515 0.125-0.972 0.126-1.23-0.109-0.258-0.235-0.318-0.707-0.348-0.943-0.0295-0.236-0.0284-0.236 0.082-0.361 0.11-0.125 0.331-0.376 0.641-0.553 0.271-0.155 0.609-0.254 1.02-0.256zm1.89 4.92c0.145-1e-3 0.286 0.0129 0.42 0.0469 0.611 0.155 1.05 0.716 1.18 1.17 0.125 0.456-0.0653 0.811-0.426 1.07-0.361 0.257-0.892 0.419-1.49 0.471-0.596 0.0518-1.26-7e-3 -1.59-0.213-0.332-0.206-0.332-0.559-0.332-0.736 0-0.177 1e-3 -0.179 0.082-0.385 0.0809-0.206 0.243-0.617 0.715-0.949 0.369-0.259 0.926-0.469 1.44-0.475z"
          />
          <path
            transform="matrix(.265 0 0 .265 56.1 41.3)"
            d="m146 272c-0.87 3e-3 -1.65 0.153-2.25 0.389-0.597 0.236-1.01 0.558-1.21 0.719-0.205 0.161-0.206 0.161-0.377 0.406-0.171 0.245-0.513 0.734-0.668 1.35-0.155 0.619-0.122 1.37-0.0762 1.92 0.0461 0.551 0.105 0.905 0.289 1.37 0.184 0.464 0.495 1.04 1.03 1.61 0.538 0.574 1.3 1.15 2.21 1.35 0.906 0.206 1.95 0.0441 2.75-0.295 0.802-0.339 1.36-0.853 1.85-1.52s0.9-1.5 1.05-2.37c0.155-0.876 0.0518-1.8-0.295-2.59-0.347-0.783-0.938-1.42-1.71-1.81s-1.73-0.534-2.6-0.531zm-0.381 2.55c0.0544-6.8e-4 0.109 1e-3 0.164 6e-3 0.438 0.0368 0.874 0.257 1.24 0.643s0.656 0.937 0.619 1.45c-0.0368 0.51-0.401 0.978-0.766 1.3-0.364 0.323-0.728 0.5-1.13 0.5-0.401 3.7e-4 -0.838-0.177-1.17-0.428-0.336-0.251-0.571-0.576-0.674-0.982-0.103-0.406-0.0755-0.894-0.0312-1.21 0.0442-0.311 0.104-0.447 0.207-0.588 0.103-0.141 0.249-0.286 0.541-0.432 0.255-0.128 0.623-0.257 1-0.262z"
          />
          <path d="m96.8 114c0 0.277 0 0.83 0.0264 1.15s0.079 0.417 0.143 0.472c0.0644 0.0544 0.14 0.07 0.199 0.0564 0.0583-0.0137 0.0992-0.0566 0.136-0.108 0.037-0.0516 0.0701-0.112 0.0838-0.258 0.0137-0.146 0.0078-0.378 2e-3 -0.61 0.246 0.308 0.491 0.616 0.648 0.793s0.225 0.224 0.311 0.261c0.0858 0.037 0.189 0.0643 0.267 0.0506s0.13-0.0682 0.167-0.16c0.037-0.0915 0.0584-0.22 0.0857-0.545 0.0273-0.324 0.0604-0.845 0.0673-1.16 0.0069-0.314-0.0126-0.421-0.0534-0.496-0.0408-0.0751-0.103-0.118-0.175-0.115-0.0722 3e-3 -0.154 0.0515-0.212 0.103-0.0576 0.0517-0.0907 0.106-0.125 0.298-0.0341 0.192-0.0692 0.521-0.104 0.85-0.32-0.431-0.639-0.861-0.837-1.11s-0.274-0.321-0.335-0.345c-0.0615-0.0245-0.108-3e-3 -0.145 0.0389-0.0371 0.042-0.0644 0.104-0.0878 0.2-0.0234 0.0955-0.0429 0.224-0.0526 0.288-0.0097 0.0642-0.0097 0.0641-0.0097 0.341z" />
          <path d="m101 113c-0.0847 0.0409-0.254 0.123-0.395 0.243-0.14 0.12-0.251 0.278-0.347 0.441s-0.175 0.333-0.209 0.518-0.0224 0.383 0.0215 0.585c0.0439 0.202 0.12 0.406 0.24 0.604s0.284 0.389 0.509 0.495c0.225 0.106 0.512 0.128 0.747 0.127 0.236-2e-5 0.421-0.0214 0.571-0.0799 0.15-0.0584 0.265-0.154 0.322-0.345 0.0575-0.191 0.0575-0.477 0.0468-0.698s-0.0321-0.377-0.125-0.469c-0.0924-0.0917-0.256-0.119-0.427-0.126-0.171-7e-3 -0.348 7e-3 -0.453 0.0644-0.105 0.0576-0.139 0.159-0.138 0.244 1.1e-4 0.0849 0.0332 0.153 0.108 0.187 0.0751 0.0339 0.192 0.0339 0.278 0.0369 0.0857 3e-3 0.14 9e-3 0.165 0.0878 0.0242 0.079 0.0184 0.231 2e-3 0.326-0.0166 0.0954-0.0439 0.134-0.123 0.158-0.0788 0.0234-0.209 0.0312-0.34 8e-3 -0.131-0.0233-0.261-0.0779-0.374-0.169-0.113-0.0915-0.209-0.22-0.269-0.353-0.0605-0.133-0.0858-0.269-0.0683-0.413s0.0779-0.296 0.153-0.423c0.0751-0.127 0.165-0.228 0.281-0.302 0.116-0.0741 0.258-0.121 0.442-0.138 0.183-0.0175 0.407-6e-3 0.564-0.0399 0.157-0.0341 0.246-0.114 0.298-0.202 0.0516-0.0876 0.0653-0.183 0.0313-0.272-0.034-0.0887-0.116-0.17-0.296-0.212-0.18-0.0411-0.459-0.0411-0.664-0.0216-0.205 0.0195-0.335 0.0584-0.401 0.078-0.0654 0.0195-0.0654 0.0196-0.15 0.0605z" />
          <path
            transform="matrix(.265 0 0 .265 56.1 41.3)"
            d="m190 268c-0.672 0.0105-1.35 0.136-1.98 0.369-0.98 0.361-1.85 0.981-2.33 1.52-0.479 0.538-0.568 0.994-0.619 1.41-0.0514 0.413-0.0667 0.781-0.0742 0.965-8e-3 0.184-7e-3 0.183 0.17 1.47 0.177 1.29 0.531 3.87 0.893 5.49 0.361 1.62 0.728 2.28 1.27 2.77 0.545 0.486 1.27 0.795 2.07 0.928 0.803 0.132 1.69 0.0887 2.67-0.191 0.979-0.28 2.06-0.797 2.78-1.44 0.722-0.641 1.09-1.41 1.19-2.15 0.103-0.744-0.0586-1.46-0.221-2.06-0.162-0.597-0.323-1.07-0.684-1.39-0.361-0.317-0.921-0.479-1.48-0.641 0.353-0.501 0.707-1 0.965-1.62 0.258-0.618 0.42-1.35 0.295-2.08-0.125-0.722-0.537-1.43-1.21-2.02-0.67-0.59-1.6-1.06-2.61-1.25-0.315-0.0576-0.639-0.0875-0.965-0.0898-0.0447-3.3e-4 -0.09-7e-4 -0.135 0zm0.273 2.44c0.567 4.8e-4 1.02 0.296 1.3 0.68s0.383 0.854 0.229 1.27-0.567 0.765-1 1.07-0.89 0.574-1.35 0.707c-0.464 0.133-0.935 0.133-1.32-0.0508-0.383-0.184-0.678-0.552-0.752-0.986-0.0742-0.435 0.0727-0.935 0.146-1.19 0.0737-0.251 0.074-0.251 0.199-0.361 0.125-0.111 0.376-0.332 0.84-0.59 0.464-0.258 1.14-0.553 1.71-0.553zm1.33 6.66c0.217-2e-3 0.432 0.0111 0.625 0.0527 0.516 0.111 0.884 0.42 1.02 0.781 0.132 0.361 0.0274 0.772-0.385 1.13-0.412 0.36-1.13 0.671-1.8 0.826-0.67 0.155-1.29 0.155-1.75 0.0449-0.464-0.11-0.773-0.333-0.848-0.701-0.0743-0.368 0.0888-0.883 0.17-1.14 0.0811-0.258 0.0811-0.258 0.229-0.354 0.147-0.0958 0.442-0.288 0.723-0.391 0.28-0.103 0.546-0.118 1.01-0.17 0.29-0.0323 0.657-0.0787 1.02-0.082z"
          />
          <path
            transform="matrix(.265 0 0 .265 56.1 41.3)"
            d="m203 269c-0.337 4e-5 -0.677 0.0236-1.02 0.0684-0.985 0.13-1.96 0.453-2.56 0.781s-0.818 0.66-1.02 1.08c-0.203 0.422-0.391 0.933-0.484 1.19-0.0936 0.255-0.0937 0.257-0.0937 0.689 0 0.433-2.9e-4 1.3 0.203 2.15 0.203 0.854 0.61 1.7 1.19 2.41 0.584 0.713 1.34 1.3 2.18 1.64 0.839 0.349 1.76 0.463 2.65 0.244s1.77-0.77 2.43-1.34c0.656-0.568 1.09-1.15 1.38-2.01 0.292-0.854 0.438-1.98 0.365-2.96-0.0727-0.984-0.364-1.83-0.947-2.45-0.583-0.625-1.46-1.03-2.39-1.27-0.612-0.154-1.25-0.234-1.89-0.234zm-0.555 2.47c0.537 4e-3 1.16 0.175 1.64 0.535 0.599 0.443 0.995 1.17 1.03 1.95 0.036 0.781-0.286 1.61-0.941 1.9-0.656 0.286-1.65 0.0257-2.3-0.266-0.656-0.291-0.979-0.613-1.16-1.05-0.178-0.437-0.209-0.989-0.225-1.27-0.0157-0.277-0.0149-0.278 0.0215-0.402s0.109-0.374 0.344-0.666c0.235-0.292 0.629-0.626 1.23-0.715 0.112-0.0166 0.233-0.0243 0.357-0.0234z"
          />
          <path d="m112 113c-6e-3 0.237-0.0165 0.711-7e-3 1.01 0.01 0.299 0.04 0.423 0.123 0.51 0.0828 0.0867 0.218 0.136 0.328 0.136 0.11-7e-5 0.196-0.0497 0.259-0.112 0.0633-0.0619 0.105-0.136 0.12-0.338 0.0152-0.201 4e-3 -0.529-7e-3 -0.857 0.309 0.356 0.618 0.711 0.82 0.933 0.203 0.222 0.299 0.31 0.4 0.364 0.101 0.0536 0.205 0.0729 0.277 0.044 0.0715-0.0289 0.11-0.106 0.197-0.449 0.0868-0.343 0.222-0.952 0.276-1.32 0.0538-0.372 0.0262-0.507-0.026-0.617-0.0523-0.11-0.129-0.196-0.249-0.21-0.12-0.014-0.283 0.0439-0.379 0.149-0.0966 0.105-0.127 0.256-0.161 0.471-0.0344 0.215-0.073 0.493-0.112 0.771-0.289-0.449-0.579-0.898-0.785-1.17s-0.331-0.357-0.441-0.401c-0.11-0.0442-0.207-0.0442-0.299 0.0275-0.0925 0.0717-0.181 0.215-0.234 0.364-0.0537 0.149-0.073 0.303-0.0923 0.457 0 0 2e-4 -2e-5 -5e-3 0.237z" />
          <path d="m117 112c-0.106 0-0.318 0-0.535 0.0482s-0.437 0.145-0.615 0.249-0.313 0.218-0.482 0.415c-0.17 0.197-0.373 0.478-0.46 0.758-0.0868 0.28-0.0564 0.558 0.0153 0.813s0.185 0.486 0.376 0.704c0.192 0.218 0.462 0.422 0.757 0.51 0.295 0.0881 0.615 0.0605 0.838 0.0123s0.35-0.117 0.47-0.209c0.12-0.0923 0.233-0.208 0.28-0.445 0.0468-0.237 0.0276-0.595-5e-3 -0.84-0.033-0.245-0.0799-0.378-0.209-0.44-0.129-0.0622-0.342-0.0539-0.535-0.0456-0.193 8e-3 -0.367 0.0165-0.492 0.0648-0.126 0.0482-0.203 0.136-0.216 0.219-0.0137 0.0828 0.0359 0.16 0.152 0.204 0.116 0.0439 0.298 0.055 0.427 0.084 0.13 0.029 0.207 0.0759 0.245 0.143 0.0384 0.0676 0.0384 0.156-4e-3 0.238-0.0427 0.0825-0.128 0.16-0.263 0.193-0.135 0.0331-0.32 0.022-0.499-0.0358-0.179-0.0578-0.353-0.163-0.459-0.288-0.106-0.125-0.145-0.271-0.15-0.416-6e-3 -0.145 0.022-0.288 0.084-0.437s0.158-0.303 0.303-0.415c0.145-0.112 0.338-0.181 0.502-0.196 0.164-0.0151 0.299 0.0235 0.424 0.0428 0.125 0.0192 0.241 0.0192 0.347-0.0386 0.106-0.0579 0.202-0.174 0.247-0.313 0.0441-0.139 0.0358-0.302-0.0453-0.402-0.0812-0.101-0.236-0.139-0.313-0.159-0.0773-0.0193-0.0774-0.0193-0.183-0.0193z" />
        </g>
      </g>
    </svg>
  );
};
