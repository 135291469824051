import {
  CommandBar,
  ICommandBarItemProps,
  ICommandBarStyles,
  Link,
  Stack,
  StackItem,
} from "@fluentui/react";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AllProductsContext } from "../Contexts/AllProductsContext";
import { ShoppingCartContext } from "../Contexts/ShoppingCartContext";
import { SizingContext } from "../Contexts/SizingContext";
import { IconTextButton } from "./IconTextButton";
import { LeftNav } from "./LeftNav";
import { Logo } from "./Logo";
import { TextLogo } from "./TextLogo";

export function TopNav() {
  const { isMobile } = useContext(SizingContext);
  const { allProducts } = useContext(AllProductsContext);
  const { totalItems } = useContext(ShoppingCartContext);
  const navigate = useNavigate();
  const menuItems = useMemo(() => {
    return allProducts?.tags?.map((tag) => {
      const path = `/tags/${tag}`;
      const link: ICommandBarItemProps = {
        key: path,
        name: tag,
        onClick: () => {
          navigate(path);
        },
      };
      return link;
    });
  }, [allProducts, navigate]);
  return (
    <Stack
      horizontal
      wrap={!isMobile}
      verticalAlign="center"
      tokens={{ childrenGap: 10 }}
    >
      {isMobile && (
        <StackItem>
          <LeftNav />
        </StackItem>
      )}
      <StackItem
        grow={!!isMobile}
        styles={
          isMobile
            ? { root: { display: "flex", justifyContent: "center" } }
            : undefined
        }
      >
        <Link
          onClick={() => navigate("/")}
          styles={{ root: { borderWidth: 0 } }}
          title="Ian Bongbong"
        >
          <Stack
            horizontal
            styles={{ root: { margin: "10px 0" } }}
            tokens={{ childrenGap: 10 }}
          >
            <Logo height={20} />
            <TextLogo height={20} />
          </Stack>
        </Link>
      </StackItem>
      {!isMobile && (
        <StackItem grow styles={{ root: { minWidth: 200 } }}>
          <CommandBar styles={commandBarStyles} items={menuItems} />
        </StackItem>
      )}
      <StackItem>
        <IconTextButton
          iconProps={{
            iconName: "ShoppingCart",
            styles: {
              root: {
                fontSize: 20,
              },
            },
          }}
          text={
            isMobile
              ? `(${totalItems})`
              : totalItems
              ? `Checkout (${totalItems})`
              : "Checkout"
          }
          onClick={() => navigate("/Checkout")}
          styles={{
            root: {
              height: 40,
            },
          }}
        />
      </StackItem>
    </Stack>
  );
}

const commandBarStyles: ICommandBarStyles = {
  root: {
    border: 0,
    height: 40,
  },
  primarySet: {
    ".ms-Button": {
      padding: "0 20px",
    },
  },
};
