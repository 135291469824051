import { Link } from "@fluentui/react";
import { Notice } from "../Common/Notice";
import { useTitle } from "../Common/UseTitle";

export function MaintenanceMode() {
  useTitle("Maintenance");
  return (
    <Notice
      title="We'll be back shortly!"
      message={
        <>
          Check out our{" "}
          <Link
            href="https://instagram.com/ianbongbong/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Instagram
          </Link>{" "}
          while you wait ❤️
        </>
      }
    />
  );
}
