import { MessageBar, MessageBarType } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  error: any;
  kind?: MessageBarType;
}

export const ErrorMessageBar = (props: Props) => {
  const { error } = props;
  const [message, setMessage] = useState<string>();
  const kind = props.kind ?? MessageBarType.error;
  const navigate = useNavigate();
  const setAndLog = useCallback(
    (message: string) => {
      setMessage(message);
      if (message) {
        (window as any).gtag("event", "exception", {
          description: message,
          fatal: kind === MessageBarType.error,
        });
      }
    },
    [kind]
  );
  useEffect(() => {
    let disposed = false;
    if (error instanceof Response) {
      setAndLog("");
      error
        .clone()
        .text()
        .then((text) => {
          if (!disposed) {
            let e: any = text;
            if (text) {
              try {
                e = JSON.parse(text);
              } catch (parseError) {}
            }

            const errorMessage = getErrorMessage(e);
            setAndLog(errorMessage || `${error.status} ${error.statusText}`);
            if (e?.code === "MaintenanceMode") {
              navigate("/maintenance");
            }
          }
        });
    } else {
      setAndLog(getErrorMessage(error));
    }

    return () => {
      disposed = true;
    };
  }, [error, navigate, setAndLog]);

  return error ? (
    <MessageBar messageBarType={kind}>{message}</MessageBar>
  ) : null;
};

function getErrorMessage(e: any): string {
  if (!e) {
    return "";
  }

  if (e instanceof Error) {
    return `${e.name}: ${e.message}`;
  }

  if (typeof e === "object") {
    if (typeof e.error === "object") {
      e = e.error;
    }

    let errorMessage = e.message || e.code;
    if (errorMessage) {
      if (Array.isArray(e.errors)) {
        const subErrors = (e.errors as any[])
          .map((x) => getErrorMessage(x))
          .filter((x) => x)
          .join(", ");
        if (subErrors) {
          errorMessage += ` (${subErrors})`;
        }
      }

      return errorMessage;
    }
  }

  if (typeof e === "string") {
    return e;
  }

  return JSON.stringify(e);
}
