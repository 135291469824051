import {
  Label,
  mergeStyleSets,
  Position,
  PrimaryButton,
  SpinButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorContext } from "../../Common/ErrorContext";
import { ErrorText } from "../../Common/ErrorText";
import { HorizontalChoiceGroup } from "../../Common/HorizontalChoiceGroup";
import { useAjax } from "../../Common/UseAjax";
import { useTitle } from "../../Common/UseTitle";
import {
  OrderProduct,
  Product,
  ProductInventory,
} from "../../server/ServerSchemas";
import { ShoppingCartContext } from "../Contexts/ShoppingCartContext";
import { ProductImages } from "./Components/ProductImages";

type Choices = { [key: string]: string };

export default function ProductView() {
  const { setError } = useContext(ErrorContext);
  const { id } = useParams<{ id: string }>();
  const [choices, setChoices] = useState<Choices>({});
  const [quantity, setQuantity] = useState("1");
  const { resource: product } = useAjax<Product>({
    setError,
    input: `/api/products/${id}`,
  });
  const { resource: inventory } = useAjax<ProductInventory>({
    setError,
    input: `/api/products/${id}/inventory`,
  });
  useTitle(product?.name);
  const options = useMemo(() => getOptions(product), [product]);
  const availabilityMap = useMemo(
    () => getAvailabilityMap(inventory),
    [inventory]
  );
  useEffect(() => {
    const choices: { [key: string]: string } = {};
    if (options) {
      const choicesFromUrl: { [key: string]: string } = {};
      (new URLSearchParams(window.location.search).get("op") || "")
        .split("__")
        .forEach((x) => {
          const [flavor, choice] = x.split("_");
          flavor && choice && (choicesFromUrl[flavor] = x);
        });
      let currentMap = availabilityMap;
      options.forEach((option) => {
        const choiceFromUrl = choicesFromUrl[option.key];
        const choice = (choices[option.key] =
          option.choices.filter((x) => x.key === choiceFromUrl)[0]?.key ||
          option.choices.filter((x) => currentMap?.[x.key])[0]?.key);
        currentMap = currentMap?.[choice];
      });
    }

    setChoices(choices);
  }, [options, availabilityMap]);

  const finalOptions = useMemo(() => {
    let currentMap = availabilityMap;
    return options?.map((option): Option => {
      const finalOption = {
        ...option,
        choices: option.choices.map((item) => {
          return {
            ...item,
            disabled: !currentMap?.[item.key],
          };
        }),
      };
      const choice = choices[option.key];
      currentMap = currentMap?.[choice];
      return finalOption;
    });
  }, [options, availabilityMap, choices]);

  const finalChoice = useMemo(
    () => getFinalChoice(product, choices),
    [product, choices]
  );
  const selectedInventory =
    finalChoice && inventory ? inventory.flavors[finalChoice] : undefined;

  const maxQuantity = selectedInventory?.quantity;
  const quantityError = !maxQuantity
    ? "Out of stock"
    : !(+quantity <= maxQuantity)
    ? "The selected quantity isn't available"
    : "";

  const { cartItems, setCartItems } = useContext(ShoppingCartContext);
  const cartItemKey = `${id}__${finalChoice}`;
  const cartQuantity = cartItems[cartItemKey]?.quantity;
  useEffect(() => {
    if (cartQuantity) {
      setQuantity(cartQuantity.toString());
    }
  }, [cartQuantity]);

  const images = useMemo(() => product?.images?.map((x) => x.url!), [product]);

  if (!product) {
    return null;
  }

  return (
    <Stack>
      <Stack horizontal wrap tokens={{ childrenGap: "25px 50px" }}>
        <StackItem shrink={false}>
          <ProductImages images={images} />
        </StackItem>
        <StackItem className={classNames.details}>
          <Stack tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{product.name}</Text>
            {finalOptions?.map((option) => {
              return (
                <HorizontalChoiceGroup
                  className={classNames.formField}
                  key={option.key}
                  label={option.text}
                  selectedKey={choices[option.key] || ""}
                  onChange={(_ev, selection) => {
                    setChoices((choices) => {
                      return {
                        ...choices,
                        [option.key]: selection?.key as string,
                      };
                    });
                  }}
                  options={option.choices}
                />
              );
            })}
            {finalChoice && (
              <StackItem>
                <Label>Price</Label>
                <Text>
                  {selectedInventory
                    ? `USD ${selectedInventory.price}`
                    : "Out of stock"}
                </Text>
              </StackItem>
            )}
            <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 20 }}>
              <StackItem>
                <SpinButton
                  className={classNames.quantityAndAdd}
                  label="Quantity"
                  labelPosition={Position.top}
                  value={quantity}
                  min={1}
                  max={maxQuantity || 0}
                  step={1}
                  disabled={!maxQuantity}
                  onChange={(_ev, value) => setQuantity(value || "")}
                  incrementButtonAriaLabel="Increase quantity"
                  decrementButtonAriaLabel="Decrease quantity"
                />
              </StackItem>
              <PrimaryButton
                className={classNames.quantityAndAdd}
                text={cartQuantity ? "Update cart" : "Add to cart"}
                disabled={!selectedInventory || !!quantityError}
                onClick={() => {
                  setCartItems((items) => {
                    const orderProduct: OrderProduct = {
                      productId: id!,
                      flavor: finalChoice!,
                      productName: product.name,
                      flavorName: options
                        .map((option) => {
                          const choiceKey = choices[option.key];
                          const choiceText = option.choices.filter(
                            (x) => x.key === choiceKey
                          )[0].text;
                          return `${option.text}: ${choiceText}`;
                        })
                        .join(", "),
                      price: selectedInventory!.price,
                      quantity: +quantity,
                    };
                    return {
                      ...items,
                      [cartItemKey]: orderProduct,
                    };
                  });
                }}
              />
            </Stack>
            <ErrorText text={quantityError} />
            {product.description && (
              <StackItem>
                <Label className={classNames.descriptionLabel}>
                  About this item
                </Label>
                <Text className={classNames.description}>
                  {product.description}
                </Text>
              </StackItem>
            )}
          </Stack>
        </StackItem>
      </Stack>
    </Stack>
  );
}

interface Option {
  key: string;
  text: string;
  choices: {
    key: string;
    text: string;
    disabled?: boolean;
  }[];
}

type AvailabilityMap = { [key: string]: AvailabilityMap };

function getAvailabilityMap(inventory: ProductInventory) {
  const availabilityMap: AvailabilityMap = {};
  Object.keys(inventory?.flavors || {})
    .filter((key) => inventory.flavors[key].quantity > 0)
    .forEach((key) => {
      let currentMap = availabilityMap;
      key.split("__").forEach((choice) => {
        currentMap[choice] = currentMap[choice] || {};
        currentMap = currentMap[choice];
      });
    });
  return availabilityMap;
}

function getOptions(product: Product) {
  return (
    product &&
    product.flavors.map((flavor) => {
      const option: Option = {
        key: flavor.id,
        text: flavor.name,
        choices: flavor.options.map((choice) => {
          const key = `${flavor.id}_${choice.id}`;
          return {
            key,
            text: choice.name,
          };
        }),
      };
      return option;
    })
  );
}

function getFinalChoice(product: Product, choices: Choices) {
  if (!product || !choices) {
    return undefined;
  }

  const choiceArray = product.flavors.map((flavor) => {
    return choices[flavor.id];
  });
  return choiceArray.every((x) => x) ? choiceArray.join("__") : undefined;
}

const classNames = mergeStyleSets({
  formField: {
    maxWidth: 400,
  },
  details: {
    maxWidth: 550,
  },
  descriptionLabel: {
    marginTop: 10,
  },
  quantityAndAdd: {
    width: 120,
  },
  description: {
    whiteSpace: "pre-line",
  },
});
