import { mergeStyleSets, Stack, StackItem } from "@fluentui/react";
import { lazy, useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ErrorContext } from "../Common/ErrorContext";
import { ErrorMessageBar } from "../Common/ErrorMessagebar";
import { useAjax } from "../Common/UseAjax";
import { AllProducts } from "../server/ServerSchemas";
import { Footer } from "./Components/Footer";
import { TopNav } from "./Components/TopNav";
import { AllProductsContext } from "./Contexts/AllProductsContext";
import { ShippingInfoProvider } from "./Contexts/ShippingInfoContext";
import { ShoppingCartProvider } from "./Contexts/ShoppingCartContext";
import { SizingContext } from "./Contexts/SizingContext";
import Home from "./Home";
import { MaintenanceMode } from "./MaintenanceMode";
import NotFound from "./NotFound";
import ProductView from "./Product/ProductView";
import Tag from "./Tag";

const Checkout = lazy(() => import("./Checkout/Checkout"));
const OrderDetails = lazy(() => import("./OrderDetails"));

export function Client() {
  const { error, setError } = useContext(ErrorContext);
  const { isMobile, contentPadding } = useContext(SizingContext);
  const { resource: allProducts, refresh } = useAjax<AllProducts>({
    setError,
    input: "/api/allProducts",
  });
  const { pathname } = useLocation();
  useEffect(() => {
    (window as any).gtag("set", "page_path", pathname);
    (window as any).gtag("event", "page_view");
    setError(undefined);
  }, [pathname, setError]);

  return (
    <AllProductsContext.Provider value={{ allProducts, refresh }}>
      <ShoppingCartProvider>
        <ShippingInfoProvider>
          <div
            className={[
              classNames.container,
              !isMobile && classNames.scrollAdjust,
            ]
              .filter((x) => x)
              .join(" ")}
          >
            <Stack>
              <TopNav />
              <ErrorMessageBar error={error} />
              <Stack
                className={classNames.content}
                styles={{ root: { padding: `0 ${contentPadding}px` } }}
              >
                <Routes>
                  <Route path={`/tags/:tag`} element={<Tag />} />
                  <Route path={`/products/:id`} element={<ProductView />} />
                  <Route path={`/checkout`} element={<Checkout />} />
                  <Route path={`/orders/:id`} element={<OrderDetails />} />
                  <Route path={`/maintenance`} element={<MaintenanceMode />} />
                  <Route path="/" element={<Home />} />
                  <Route element={<NotFound />} />
                </Routes>
              </Stack>
              <StackItem align="center">
                <Footer />
              </StackItem>
            </Stack>
          </div>
        </ShippingInfoProvider>
      </ShoppingCartProvider>
    </AllProductsContext.Provider>
  );
}

const classNames = mergeStyleSets({
  container: {
    maxWidth: 1000,
    margin: "auto",
    overflowWrap: "anywhere",
    ".ms-Button--primary": {
      height: "32px !important",
    },
  },
  scrollAdjust: {
    paddingLeft: "calc(100vw - 100%)",
  },
  content: {
    paddingTop: 20,
  },
});
