import {
  IButtonProps,
  IconButton,
  IStackStyles,
  IStackTokens,
  Stack,
  Text,
} from "@fluentui/react";

export function IconTextButton(props: IButtonProps) {
  return (
    <IconButton
      {...props}
      onRenderIcon={(props, defaultRender) => {
        return (
          <Stack
            horizontal
            verticalAlign="center"
            tokens={stackTokens}
            styles={stackStyles}
          >
            {defaultRender!(props)}
            <Text>{props?.text}</Text>
          </Stack>
        );
      }}
      styles={{
        ...props.styles,
        root: {
          ...(props.styles?.root as any),
          width: "inherit",
        },
      }}
    />
  );
}

const stackTokens: IStackTokens = {
  childrenGap: 3,
};

const stackStyles: IStackStyles = {
  root: {
    marginRight: 3,
  },
};
