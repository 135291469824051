import { useEffect } from "react";

export function useTitle(title?: string, ignore?: boolean) {
  ignore = !!ignore;
  useEffect(() => {
    if (!ignore) {
      document.title = title ? `${title} | Ian Bongbong` : "Ian Bongbong";
    }
  }, [title, ignore]);
}
