import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

export interface ShippingInfo {
  name: string;
  delivery: boolean;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
}

export const ShippingInfoContext = createContext<{
  shipping: ShippingInfo;
  setShipping: Dispatch<SetStateAction<ShippingInfo>>;
}>(undefined as any);

export function ShippingInfoProvider(props: PropsWithChildren<{}>) {
  const [shipping, setShipping] = useState<ShippingInfo>({
    name: "",
    delivery: true,
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
  });
  return (
    <ShippingInfoContext.Provider value={{ shipping, setShipping }}>
      {props.children}
    </ShippingInfoContext.Provider>
  );
}
