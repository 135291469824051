import { Image, ImageFit, Stack, useTheme } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { SizingContext } from "../../Contexts/SizingContext";

interface Props {
  images?: string[];
}

export function ProductImages(props: Props) {
  const { images } = props;
  const theme = useTheme();
  const [current, setCurrent] = useState<string>();
  const { contentWidth } = useContext(SizingContext);
  useEffect(() => {
    if (images) {
      setCurrent(images[0]);
    } else {
      setCurrent(undefined);
    }
  }, [images]);
  const imageWidth = Math.min(contentWidth, 350);
  return (
    <Stack
      tokens={{ childrenGap: 10 }}
      styles={{ root: { width: imageWidth } }}
    >
      {current && (
        <Image
          src={current}
          imageFit={ImageFit.centerContain}
          width={imageWidth}
          height={imageWidth}
          styles={{
            root: {
              backgroundColor: theme.semanticColors.infoBackground,
              boxShadow:
                "0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%)",
            },
          }}
          alt="Product image"
        />
      )}
      {images && images.length > 1 && (
        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
          {images.map((item, i) => (
            <Image
              key={i}
              src={item}
              imageFit={ImageFit.centerCover}
              width={50}
              height={50}
              onClick={() => setCurrent(item)}
              alt={`Product image ${i}`}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
