async function ajaxRaw(
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  if (
    typeof input === "string" &&
    input.charAt(0) === "/" &&
    process.env.NODE_ENV === "production"
  ) {
    input = `https://api.ianbongbong.com${input}`;
  }

  const res = await fetch(input, init);
  if (!res.ok) {
    throw res;
  }

  return res;
}

export interface AjaxInit extends RequestInit {
  jsonBody?: any;
}

export async function ajax<T>(input: RequestInfo, init?: AjaxInit): Promise<T> {
  if (init?.jsonBody) {
    init.body = JSON.stringify(init.jsonBody);
    init.headers = new Headers(init.headers);
    init.headers.set("Content-Type", "application/json");
  }

  const res = await ajaxRaw(input, init);
  const lenStr = res.headers.get("Content-Length");
  if (lenStr !== "0") {
    return (await res.json()) as T;
  } else {
    return undefined as any;
  }
}
