import { ITextStyles, Text } from "@fluentui/react";

export const ErrorText = (props: { text?: string }) => {
  const { text } = props;
  return text ? <Text styles={textStyles}>{text}</Text> : null;
};

const textStyles: ITextStyles = {
  root: {
    color: "#a3262c",
    fontSize: 12,
  },
};
