import { lazy, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Client } from "./Client/Client";
import { SizingProvider } from "./Client/Contexts/SizingContext";
import { ErrorContext } from "./Common/ErrorContext";
import { LoadingSpinner } from "./Common/LoadingSpinner";
import { ThemeDetector } from "./Common/ThemeDetector";

const Admin = lazy(() => import("./Admin/Admin"));
const NotFound = lazy(() => import("./Client/NotFound"));
const PrintLabel = lazy(() => import("./Admin/PrintLabel/PrintLabel"));

function App() {
  const [error, setError] = useState<any>();
  return (
    <ThemeDetector>
      <ErrorContext.Provider value={{ error, setError }}>
        <SizingProvider>
          <Router>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route
                  path={`/admin/label/:encodedUrl`}
                  element={<PrintLabel />}
                />
                <Route path="/admin/*" element={<Admin />} />
                <Route path="*" element={<Client />} />
                <Route element={<NotFound />} />
              </Routes>
            </Suspense>
          </Router>
        </SizingProvider>
      </ErrorContext.Provider>
    </ThemeDetector>
  );
}

export default App;
