import { AzureThemeLight } from "@fluentui/azure-themes";
import { ThemeProvider } from "@fluentui/react";
import { PropsWithChildren, useEffect, useState } from "react";

export const ThemeDetector = (props: PropsWithChildren<{}>) => {
  const [theme, setTheme] = useState(() => getTheme(getDarkQuery().matches));
  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => {
      setTheme(getTheme(e.matches));
    };
    const query = getDarkQuery();
    query.addEventListener("change", (e) => {
      setTheme(getTheme(e.matches));
    });

    return () => {
      query.removeEventListener("change", handler);
    };
  });
  return (
    <ThemeProvider applyTo="body" theme={theme}>
      {props.children}
    </ThemeProvider>
  );
};

function getDarkQuery() {
  return window.matchMedia("(prefers-color-scheme: dark)");
}

function getTheme(isDark: boolean) {
  return AzureThemeLight;
}
