import { useBoolean } from "@fluentui/react-hooks";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ajax, AjaxInit } from "./Ajax";

interface Options {
  input?: RequestInfo;
  init?: AjaxInit;
  setError: Dispatch<SetStateAction<any>>;
}

interface Contract<T> {
  resource: T;
  refresh: () => void;
}

export function useAjax<T>(options: Options): Contract<T> {
  const { setError, input, init } = options;
  const [resource, setResource] = useState<T>();
  const [force, { toggle: refresh }] = useBoolean(false);
  useEffect(() => {
    if (!input) {
      setResource(undefined);
      return;
    }

    let disposed = false;
    (async () => {
      try {
        const result = await ajax<T>(input, init);
        !disposed && setResource(result);
      } catch (e) {
        !disposed && setError(e);
      }
    })();
    return () => {
      disposed = true;
    };
  }, [setResource, input, init, setError, force]);
  return {
    resource: resource as T,
    refresh,
  };
}
