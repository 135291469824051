import { Stack, Text } from "@fluentui/react";
import { PropsWithChildren, ReactNode, useContext } from "react";
import { Logo } from "../Client/Components/Logo";
import { SizingContext } from "../Client/Contexts/SizingContext";

interface Props {
  title: string;
  message?: ReactNode;
}

export function Notice(props: PropsWithChildren<Props>) {
  const { title, message, children } = props;
  const { contentWidth } = useContext(SizingContext);
  return (
    <Stack
      tokens={{ childrenGap: 20 }}
      horizontalAlign="center"
      styles={{ root: { paddingTop: 30 } }}
    >
      <Logo height={Math.min(contentWidth * 0.5, 350)} />
      <Text variant="xLargePlus">{title}</Text>
      {message && <Text variant="large">{message}</Text>}
      {children}
    </Stack>
  );
}
