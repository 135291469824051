import {
  IButtonStyles,
  IconButton,
  IIconProps,
  INavLink,
  INavLinkGroup,
  mergeStyleSets,
  Nav,
  useTheme,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AllProductsContext } from "../Contexts/AllProductsContext";
import { ShoppingCartContext } from "../Contexts/ShoppingCartContext";

export function LeftNav() {
  const theme = useTheme();
  const id = useId("container");
  const { allProducts } = useContext(AllProductsContext);
  const { totalItems } = useContext(ShoppingCartContext);
  const [expanded, { toggle, setFalse: hide }] = useBoolean(false);
  const navigate = useNavigate();
  const groups = useMemo(() => {
    const createLink = (
      path: string,
      name: string,
      restProps?: Partial<INavLink>
    ) => {
      const link: INavLink = {
        key: path,
        name: name,
        url: "",
        onClick: () => {
          navigate(path);
          hide();
        },
        isExpanded: !!restProps?.links,
        ...restProps,
      };
      return link;
    };
    const group: INavLinkGroup = {
      links: [
        createLink("/", "All Products", {
          links: allProducts?.tags?.map((tag) => {
            return createLink(`/tags/${tag}`, tag);
          }),
        }),
      ],
    };
    if (totalItems) {
      group.links.push(
        createLink("/Checkout", `Checkout (${totalItems} items)`, {
          disabled: !totalItems,
          iconProps: {
            iconName: "ShoppingCart",
          },
        })
      );
    }

    return [group];
  }, [allProducts, hide, navigate, totalItems]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!document.getElementById(id)?.contains(e.target as Node)) {
        hide();
      }
    };
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, [id, hide]);

  return (
    <div id={id} className={classNames.outer}>
      <IconButton
        iconProps={iconProps}
        styles={toggleStyles}
        onClick={toggle}
        ariaLabel={`${expanded ? "Close" : "Open"} navigation pane`}
      />
      {expanded && (
        <div
          className={
            expanded
              ? `${classNames.inner} ${classNames.shadow}`
              : classNames.inner
          }
          style={{
            background: theme.semanticColors.bodyBackground,
          }}
        >
          <Nav
            selectedKey=""
            groups={groups}
            styles={{
              root: {
                width: 250,
                height: document.documentElement.scrollHeight,
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

const classNames = mergeStyleSets({
  outer: {
    position: "relative",
  },
  inner: {
    position: "absolute",
    top: 0,
    zIndex: 1,
    paddingTop: 40,
  },
  shadow: {
    boxShadow: "0 0 10px rgb(0 0 0 / 75%)",
    clipPath: "inset(0 -10px 0 0)",
  },
});

const iconProps: IIconProps = {
  iconName: "GlobalNavButton",
  styles: {
    root: {
      fontSize: 20,
    },
  },
};

const toggleStyles: IButtonStyles = {
  root: {
    height: 40,
    width: 40,
    zIndex: 2,
  },
};
